import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import ModuleQVApp from './moduleQVApp'
import MODULE_QV_UTILS from './moduleQVUtils'
import MODULE_QV_PAGE_EVENTS from './moduleQVPageEvents'
import MODULE_TYPES from '../common/common-components/modules/moduleTypes'
import ModuleError from '../common/common-components/modules/moduleError'
import ModuleSkeleton from '../common/common-components/modules/moduleSkeleton'



function ModuleQVAppContainer(props){
  let {module,urlInfo,moduleChecked,getVehicleModuleConnect} = props
  useEffect(()=>{
  },[])
  useEffect(()=>{
    if(urlInfo && urlInfo.params['module_id'] && !moduleChecked){
      getVehicleModuleConnect(urlInfo.vin,urlInfo.params['module_id'])
      MODULE_QV_UTILS.getThirdPartyTags(urlInfo.vin).then(response=>{return response.json()}).then(res=>{
        if(res){
          MODULE_QV_PAGE_EVENTS.initThirdPartyTags(res)
        }
      })
    }
    if(module){
      MODULE_QV_UTILS.initializeEtsSocket(urlInfo.vin,urlInfo.params['enhanced_webicon_id'],module.module_name)
    }
  },[module])
  if(module){
    return(<ModuleQVApp module={module}/>)
  }
  else if(!moduleChecked){
    return(<ModuleSkeleton/>)
  }
  return <ModuleError/>
}
const mapStateToProps = state =>({
  ...state.moduleQV,
  urlInfo:state.urlInfo
})
const mapDispatchToProps = {
  getVehicleModuleConnect: MODULE_QV_UTILS.getVehicleModule,
}
export default connect(mapStateToProps,mapDispatchToProps) (ModuleQVAppContainer)
