export function initThirdPartyTags(thirdPartyTags){
  if(thirdPartyTags['google-analytics'] && thirdPartyTags['google-analytics'].length){
    thirdPartyTags['google-analytics'].forEach(tag=>{
      // Create the first script tag for loading gtag.js
      var gtagScript = document.createElement("script")
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag.measurement_id}`
      gtagScript.async = true

      // Create the second script tag for gtag configuration
      var gtagConfigScript = document.createElement("script")
      gtagConfigScript.textContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${tag.measurement_id}');
      `
      document.body.insertBefore(gtagScript, document.body.firstChild);
      document.body.insertBefore(gtagConfigScript, gtagScript.nextSibling);
    })
  }
  if(thirdPartyTags['tealium'] && thirdPartyTags['tealium'].length){
    const urlParams = new URLSearchParams(window.location.search);
    const tealiumVisitorId = urlParams.get('TMVID')
    if(tealiumVisitorId){
      localStorage.setItem("tealium_visitor_id", tealiumVisitorId);
    }
    thirdPartyTags['tealium'].forEach(tag=>{
      var tealiumScript = document.createElement("script")
      tealiumScript.src = `//tags.tiqcdn.com/utag/${tag.account}/${tag.profile}/${tag.environment}/utag.js`
      tealiumScript.type = "text/javascript"
      tealiumScript.async = true
      document.body.insertBefore(tealiumScript, document.body.firstChild)
    })
  }
}

export default {
  initThirdPartyTags,
}
