import React, { useEffect, useState, useRef } from "react"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import DownloadIcon from "../../common-assets/icons/downloadIcon"
import "./styles/modulePdf.scss"

const options = {
  cMapUrl: '/cmaps/',
}

function ModulePdf(props) {
  let { module } = props
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scaleIndex, setScaleIndex] = useState(7)
  const [initialPdfWidth, setInitialPdfWidth] = useState(null)
  const modulePdfElement = useRef(null)
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)

  const setupPdfSizing = (view) => {
    const originalPDFWidth = view[2]
    const originalPDFHeight = view[3]

    if (
      modulePdfElement.current.clientWidth /
        modulePdfElement.current.clientHeight >
      1
    ) {
      if (originalPDFWidth / originalPDFHeight > 1) {
        setInitialPdfWidth(modulePdfElement.current.clientWidth * 0.9)
      } else {
        setInitialPdfWidth(modulePdfElement.current.clientWidth * 0.6)
      }
    } else {
      setInitialPdfWidth(modulePdfElement.current.clientWidth)
    }
  }

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    setIsMobileOrTablet(
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      )
    )
  }, [])

  const scaleValues = [
    0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3,
    4, 5,
  ]
  const decreaseScale = () => {
    if (scaleIndex > 0) {
      setScaleIndex(scaleIndex - 1)
    }
  }
  const increaseScale = () => {
    if (scaleIndex < scaleValues.length - 1) {
      setScaleIndex(scaleIndex + 1)
    }
  }
  const handlePage = (page) => {
    setPageNumber(page)
  }
  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const savePdfAsBlob = async (pdfUrl) => {
    const response = await fetch(pdfUrl)
    const blob = await response.blob()
    return blob
  }
  const downloadPdf = async () => {
    const fetchedBlob = await savePdfAsBlob(module.url)
    const downloadBlob = new Blob([fetchedBlob], {
      type: "application/pdf;base64",
    })
    const data = window.URL.createObjectURL(downloadBlob)
    const link = document.createElement("a")
    link.href = data
    link.download = `${module.module_name}.${module.content_type}`
    link.click()
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
    }, 100)
  }

  return (
    <div className="module-pdf" ref={modulePdfElement}>
      <div className="download-icon" onClick={downloadPdf}>
        <DownloadIcon />
      </div>
      <div className="module-viewer-container">
        <div className="module-viewer-header">
          <div className="module-viewer-header-name">{module.label}</div>
          {isMobileOrTablet ? (
            <div className="module-viewer-pagination">
              <button onClick={() => handlePage(1)} disabled={pageNumber <= 1}>
                First
              </button>
              <button onClick={() => handlePage(pageNumber - 1)} disabled={pageNumber <= 1}>
                Previous
              </button>
              <span className="pageNumbers">{pageNumber} of {numPages}</span>
              <button
                onClick={() => handlePage(pageNumber + 1)}
                disabled={pageNumber >= numPages}
              >
                Next
              </button>
              <button
                onClick={() => handlePage(numPages)}
                disabled={pageNumber >= numPages}
              >
                Last
              </button>
            </div>
          ) : null}
          {!isMobileOrTablet ? (
            <div className="module-viewer-header-tools">
              <button
                onClick={() => {
                  decreaseScale()
                }}
              >
                -
              </button>
              <div>{`${Math.round(scaleValues[scaleIndex] * 100)} %`}</div>
              <button
                onClick={() => {
                  increaseScale()
                }}
              >
                +
              </button>
            </div>
          ) : null}
        </div>
        <div className="module-viewer-content-container">
          {isMobileOrTablet ? (
            <Document file={module.url} onLoadSuccess={handleLoadSuccess} options={options}>
              <Page pageNumber={pageNumber} />
            </Document>
          ) : (
            <Document
              file={module.url}
              loading={<React.Fragment />}
              onLoadSuccess={(pdf) => {
                setNumPages(pdf.numPages)
                pdf.getPage(1).then((page) => {
                  setupPdfSizing(page.view)
                })
              }}
              options={options}
            >
              <Pages
                scale={scaleValues[scaleIndex]}
                numPages={numPages}
                width={initialPdfWidth}
              />
            </Document>
          )}
        </div>
      </div>
    </div>
  )
}
function Pages(props) {
  const { numPages, scale, width } = props

  let pages = []
  for (let i = 1; i < numPages + 1; i++) {
    pages.push(
      <Page
        scale={scale}
        width={width}
        pageNumber={i}
        loading={<React.Fragment />}
      />
    )
  }
  return <React.Fragment>{pages}</React.Fragment>
}

export default ModulePdf