import parseURL from '../utils/parseURL'
import packageJson from '../../package.json'


let baseUrl = "https://djapi.autoipacket.com/"
if (process.env.API_URL) {
  baseUrl = process.env.API_URL + '/'
}

const AIP_DJAPI_URLS = {
  BASE_URL: baseUrl,
  VEHICLE_PAGE: `${baseUrl}v1/vdp/vehicle`,
  V3_MIGRATED: `${baseUrl}v1/vdp/v3-migrated`,
  VEHICLE_MODULES: `${baseUrl}v1/vdp/modules`,
  VEHICLE_IMAGES: `${baseUrl}v1/vdp/vehicle/images`,
  SALESPERSON_INFO: `${baseUrl}v1/vdp/sales-person`,
  ZOOM_MEETING_REQUEST: `${baseUrl}v1/vdp/zoom-meeting-request`,
  CONFIRM_AVAILABILITY: `${baseUrl}v1/vdp/confirm-availability`,
  PAYMENT_CALCULATOR: `${baseUrl}v1/vdp/payment-calculator`,
  FLAG_ATTACHMENT: `${baseUrl}v1/vdp/flag-attachment/`,
  CONFIG: `${baseUrl}v1/vdp/configuration`,
  SKIN:`${baseUrl}v1/vdp/skin`,
  TRACKING:`${baseUrl}v1/vdp/tracking`,
  GET_3PT_TAGS: `${baseUrl}v1/vdp/third-party-tags`,
}



const AIP_DJAPI_HEADERS ={
  'AIP-Application-Type': 4,
  'AIP-Application-Version': packageJson.version
}

export default function AIP_DJAPI(url,params){
  if(params){
    params.headers ={
      ...AIP_DJAPI_HEADERS,
    }
  }
  else{
    params = {
      headers:AIP_DJAPI_HEADERS
    }
  }
  return fetch(url,params)
}

export {AIP_DJAPI_URLS,AIP_DJAPI_HEADERS}
